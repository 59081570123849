<template>
  <div class="d-flex flex-row flex-wrap justify-content-between">
    <div v-for="item in state.data.items.filter(item => !item.deleted)" :key="item.id" class="p-2 me-2 mb-3 rounded border">
      <div class="d-flex flex-row align-items-start mb-2">
        <p-select v-model:value="item.kind" :options="selectOptions" borderless/>
        <p-tooltip text="Удалить" class="ms-2 fs-4">
          <p-icon name="delete" clickable @click="removeItem(item.id)" class="fs-5"/>
        </p-tooltip>
      </div>

      <file-selector v-model="item.files" @update:modelValue="fileSelected(item)" v-if="item.photo === null"
                     :accept="['image/png', 'image/jpeg']">
        <dropzone v-slot="{ hovered }" class="foo">
          <div
            class="dropzone w-100 d-flex align-items-center justify-content-around h-100"
            :class="{ 'border-blue-200': hovered }"
          >
            <p-editable-preview v-if="item.preview !== null" :alt="item.kind" :src="item.preview"
                                @edit="item.files = []; item.preview = null; state.pendingPhotos += 1"
                                @cropped="(data) => imgCropped(item, data)"
            />
            <dialog-button class="btn btn-outline-dark px-5 my-5" v-else>Загрузить фото</dialog-button>
          </div>
        </dropzone>
      </file-selector>

      <p-editable-preview :alt="item.kind" :src="MEDIA_URL + item.photo"
                          @edit="item.photo = null; state.pendingPhotos += 1" @cropped="(data) => imgCropped(item, data)" v-else/>
    </div>
  </div>

  <div class="d-flex flex-row w-100 flex-wrap">
        <span v-for="option in options" :key="option"
              class="rounded-pill text-nowrap me-2 px-2 mb-2 pointer border border-2 item-option" @click="addItem(option)">
          {{ option }}
        </span>
  </div>

  <p-input class="w-100" label="Комментарий" textarea v-model:value="state.data.comment" :error="v$.comment.$errors"/>
  <button class="btn btn-dark px-4" @click.prevent="submit" :disabled="state.pendingPhotos !== 0">
    {{ ticket.status === 'in_progress' ? 'Отправить отчет' : 'Сохранить изменения' }}
  </button>
  <p-server-error :error="serverError"/>

  <p-confirmation-modal
    v-if="state.itemToDelete !== null"
    @accept="deleteApproved"
    @cancel="state.itemToDelete = null"
  >
    <template v-slot:title>
      {{ state.itemToDelete.kind }}
    </template>
    <template v-slot:confirmation-text>
      <b>{{ state.itemToDelete.kind }}</b> содержит заполненную информацию, вы действительно хотите его удалить?
    </template>
  </p-confirmation-modal>
</template>

<script setup>
import { defineEmits, defineProps, reactive } from 'vue'
import { generateId, MEDIA_URL } from '@/common'
import PServerError from '@/components/ui/ServerError'
import PEditablePreview from '@/components/ui/EditablePreview'
import { useModelWrapper } from '@/composables/model'
import { DialogButton, Dropzone, FileSelector } from 'vue3-file-selector'
import form from '@/composables/form'
import api from '@/api'
import urls from '@/api/urls'
import { helpers } from '@vuelidate/validators'
import PConfirmationModal from '@/components/modals/ConfirmationModal'

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})
const emit = defineEmits(['update:modelValue', 'saved'])
const ticket = useModelWrapper(props, emit)

const state = reactive({
  preview: {
    show: false,
    title: null,
    src: null,
  },
  data: {
    items: (ticket.value.reportPhotos || []).map(item => ({
      ...item,
      id: generateId(10),
      virtual: false,
      files: [],
      preview: null,
      deleted: false,
    })),
    comment: ticket.value.reportComment,
  },
  pendingPhotos: 0,
  itemToDelete: null,
})

const options = [
  'Счетчик ГВС',
  'Счетчик ХВС',
  'Старый счетчик ГВС',
  'Старый счетчик ХВС',
  'Акт поверки',
  'Акт замены',
  'Паспорт счетчика',
]
const selectOptions = options.map(option => ({
  name: option,
  value: option,
}))

const addItem = (kind) => {
  state.data.items.push({
    id: generateId(10),
    kind,
    files: [],
    photo: null,
    data: null,
    preview: null,
    virtual: true,
  })
  state.pendingPhotos += 1
}

const removeItem = (id) => {
  const itemToRemove = state.data.items.filter((el) => el.id === id)[0]
  if (itemToRemove.virtual) {
    state.data.items = state.data.items.filter((el) => el.id !== id)
  } else {
    if (itemToRemove.data && itemToRemove.data.created) {
      state.itemToDelete = itemToRemove
    } else {
      itemToRemove.deleted = true
    }
  }
}

const deleteApproved = () => {
  state.itemToDelete.deleted = true
  state.itemToDelete = null
}

const fileSelected = async (item) => {
  const file = item.files[0]
  const buffer = await file.arrayBuffer()
  const blob = new Blob([buffer])
  item.preview = URL.createObjectURL(blob)
  state.pendingPhotos -= 1
}

const imgCropped = (item, blob) => {
  let filename
  if (item.photo) {
    const filenameParts = item.photo.split('/')
    filename = filenameParts[filenameParts.length - 1]
  } else {
    filename = item.files[0].name
  }
  item.photo = null
  item.files = [new File([blob], filename, { type: blob.type })]
  item.preview = URL.createObjectURL(blob)
}

const {
  v$,
  submit,
  serverError,
} = form(state.data, {
  comment: {
    photoOrComment: helpers.withMessage('Введите комментирий или прикрепите фото', () => state.data.comment || state.data.items.length > 0),
  },
}, async () => {
  const sendFirstReport = async () => {
    const formData = new FormData()
    formData.set('ticket_id', ticket.value.id)
    formData.set('comment', state.data.comment)

    state.data.items.forEach(item => formData.append('photo_kinds', item.kind))
    state.data.items.forEach(item => formData.append('photos', item.files[0]))
    await api.post(urls.TICKETS.NEW_REPORT, formData)
    ticket.value = await api.get(urls.TICKETS.GET, { params: { ticket_id: ticket.value.id } })
    emit('saved')
  }
  const editReport = async () => {
    const existentItems = state.data.items.filter(item => !item.virtual)
    const newItems = state.data.items.filter(item => item.virtual)

    const formData = new FormData()
    formData.set('ticket_id', ticket.value.id)
    formData.set('comment', state.data.comment)

    existentItems.forEach(item => formData.append('delete_mask', item.deleted))

    existentItems.filter(item => item.files.length > 0).forEach(item => formData.append('update_photos', item.files[0]))
    existentItems.map((item, i) => ({
      ...item,
      i,
    })).filter(item => item.files.length > 0).forEach(item => formData.append('update_photo_indices', item.i.toString()))

    existentItems.forEach(item => formData.append('update_kinds', item.kind))
    newItems.forEach(item => formData.append('new_photos', item.files[0]))
    newItems.forEach(item => formData.append('new_photo_kinds', item.kind))

    await api.post(urls.TICKETS.EDIT_REPORT, formData)
    ticket.value = await api.get(urls.TICKETS.GET, { params: { ticket_id: ticket.value.id } })
    emit('saved')
  }

  if (ticket.value.status === 'todo' || ticket.value.status === 'in_progress') {
    await sendFirstReport()
  } else {
    await editReport()
  }
})
</script>

<style scoped>
.item-option:hover {
  background-color: #2b3747;
  color: white;
  transition: 0.3s;
  border-color: #2b3747 !important;
}
</style>
