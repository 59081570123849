<template>
  <p-base-modal :show="true" @close="$emit('closed')" modal-class="photo-modal">
    <template v-slot:title>
      {{ title }}
    </template>
    <template v-slot:content>
      <div class="m-3 ms-0 p-0 ">
        <vue-cropper
          class="rounded-3 photo-in-modal"
          ref="cropper"
          :view-mode="2"
          :auto-crop-area="1"
          :src="src"
          :alt="title"
          :check-orientation="false"
          :check-cross-origin="false"
        />
        <div class="d-flex flex-row align-items-center">
          <p-icon name="refresh" clickable class="ps-3" style="transform: scaleX(-1)" @click="cropper.rotate(-90)"/>
          <button class="btn btn-outline-dark w-100 my-3" @click.prevent="submit">Готово</button>
          <p-icon name="refresh" clickable class="ps-3" @click="cropper.rotate(90)"/>
        </div>
      </div>
    </template>
  </p-base-modal>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import PBaseModal from './Base'

defineProps({
  title: {
    type: String,
    required: true,
  },
  src: {
    type: String,
    required: true,
  },
})
const emit = defineEmits(['cropped'])
const cropper = ref(null)

const submit = async () => {
  cropper.value.getCroppedCanvas().toBlob((blob) => {
    emit('cropped', blob)
  })
}
</script>

<style>
.photo-modal {
  max-height: 86vh !important;
  max-width: 100vw !important;
  width: auto;
}

.photo-in-modal {
  max-height: 72vh;
}
</style>
