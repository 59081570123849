<template>
  <div class="container mt-3">
    <div class="w-100 d-flex align-content-center my-3" v-if="state.ticket && state.ticket.rejectedByWorker">
      <p-icon name="error" class="text-warning"/>
      <span class="ms-2 fst-italic">
        Поверщик отказался от заявки. Отредактируйте и сохраните заявку, чтобы она попала на подтверждение поверщику.
      </span>
    </div>
    <div class="row" v-if="state.ticket">
      <!-- Form select (ticket/photo report) -->
      <div class="col-3">
        <div class="mb-3 pointer d-flex flex-row" @click="state.selectedMeterIndex = null"
             :class="state.selectedMeterIndex === null ? 'fw-bold' : ''">
          Информация о заявке
          <p-tooltip text="В отчете отсутствуют фото счетчиков. Отредактируйте фотографии."
                     v-if="state.ticket.status === 'lab_review' && meters.length === 0"
                     class="ms-2">
            <p-icon name="warning" color="warning" class="fw-6"/>
          </p-tooltip>
          <div v-else>
            <p-icon name="done" color="success" class="fw-bold ms-2" style="font-size: 1.2rem !important;"
                    v-if="state.ticket.status !== 'todo' && state.ticket.status !== 'in_progress' && state.ticket.status !== 'wait'"/>
          </div>
        </div>
        <div
          v-for="(report, index) in meters" :key="index" @click="state.selectedMeterIndex = index" class="mb-3 pointer"
          :class="index === state.selectedMeterIndex ? 'fw-bold' : ''"
        >
          {{ report.kind }}
          <p-icon name="done" color="success" class="fw-bold" v-if="report.data && report.data.created" style="font-size: 1.2rem !important;"/>
          <p-tooltip text="Счетчик неисправен. Погрешность показаний превышает максимально допустимую" class="d-inline">
            <p-icon name="error" color="danger" class="ms-1" style="font-size: 1.2rem !important;"
                    v-if="report.data && report.data.workDate && report.data.nextWorkDate && report.data.workDate.toString() === report.data.nextWorkDate.toString()"
            />
          </p-tooltip>
        </div>
        <!-- Actions -->
        <p-ticket-actions :is-lab="isLab" v-model="state.ticket" :meters-filled="metersFilled && meters.length > 0"/>
      </div>
      <!-- Form (ticket/meter report) -->
      <div class="col-5">
        <p-meter-report-form
          class="pe-5 mb-5"
          :kind="selectedMeter.kind"
          :ticket-id="state.ticket.id"
          :img="MEDIA_URL + selectedMeter.photo"
          :report-id="selectedMeter.originalIndex"
          v-model="selectedMeter.data"
          v-if="selectedMeter"
          @saved="selectedMeter.data.created = true"
          @next="state.selectedMeterIndex = (state.selectedMeterIndex + 1) % meters.length"
        />
        <p-ticker-form :disabled="modificationDisabled" :ticket="state.ticket" class="px-5 py-3 mb-5" v-else
                       @created="ticketUpdated"/>
      </div>
      <!-- comment/acts -->
      <div class="col-4">
        <div v-if="!selectedMeter && state.ticket.reportComment">
          <p class="fw-bold">Комментарий исполнителя:</p>
          <p class="border rounded p-2">
            {{ state.ticket.reportComment }}
          </p>
        </div>
        <div v-if="reportActs.length > 0" class="carousel slide w-100 my-auto" data-bs-ride="carousel">
          <h5>{{ reportActs[state.selectedActIndex].kind }}</h5>
          <div class="carousel-indicators">
            <button
              class="carousel-control-prev mb-4"
              type="button"
              v-if="state.selectedActIndex > 0"
              @click="state.selectedActIndex -= 1"
            >
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Назад</span>
            </button>
            <button
              class="carousel-control-next mb-4"
              type="button"
              v-if="state.selectedActIndex + 1 < reportActs.length"
              @click="state.selectedActIndex += 1"
            >
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Вперед</span>
            </button>
            <button
              type="button"
              :class="index === state.selectedActIndex ? 'active' : ''"
              v-for="(report, index) in reportActs"
              :key="report.kind"
              :aria-label="report.kind"
              data-bs-target
              @click="state.selectedActIndex = index"
            ></button>
          </div>
          <div class="carousel-inner rounded">
            <div class="carousel-item bg-dark" :class="index === state.selectedActIndex ? 'active' : ''"
                 v-for="(report, index) in reportActs" :key="report.kind">
              <p-photo class="h-100 w-100 rounded" :src="MEDIA_URL + report.photo" :alt="report.kind"
                       v-if="index === state.selectedActIndex"
                       style="height: 800px !important;"/>
              <div class="carousel-caption">
                <h5>{{ report.kind }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import { MEDIA_URL } from '@/common'
import PMeterReportForm from '@/components/forms/MeterReport'
import PTickerForm from '@/components/forms/Ticket'
import { computed, onMounted, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import PTicketActions from './Actions'

const router = useRouter()
const store = useStore()
const state = reactive({
  ticket: null,
  selectedActIndex: 0,
  selectedMeterIndex: null,
})

const acts = [
  'Акт поверки',
  'Акт замены',
  'Паспорт счетчика',
]
const reportPhotos = computed(() => ((state.ticket || {}).reportPhotos || []))
const reportActs = computed(() => reportPhotos.value.filter((report) => acts.indexOf(report.kind) >= 0))

const meters = computed(() => reportPhotos.value.map((report, index) => ({
  ...report,
  originalIndex: index,
})).filter((report) => acts.indexOf(report.kind) < 0))
const metersFilled = computed(() => meters.value.reduce((filled, report) => Boolean(filled && report.data && report.data.created), true))
const selectedMeter = computed(() => meters.value[state.selectedMeterIndex])

const modificationDisabled = computed(() => state.ticket && (state.ticket.status === 'manager_review' || state.ticket.status === 'done'))
const isLab = computed(() => store.state.company ? store.state.company.kind === 'lab' : false)

const ticketUpdated = (ticket) => {
  state.ticket = ticket
}

watch(() => state.ticket, () => {
  if (!state.ticket || !state.ticket.reportPhotos) {
    return
  }
  for (const report of state.ticket.reportPhotos) {
    if (!report.data) {
      report.data = {}
    } else {
      report.data.created = true
    }
  }
})

const updateTicket = async (ticket) => {
  state.ticket = ticket
}

onMounted(async () => {
  const ticketId = router.currentRoute.value.params.id
  try {
    state.ticket = await api.get(urls.TICKETS.GET, { params: { ticket_id: ticketId } })
  } catch (err) {
    await router.push({ name: 'dashboard' })
  }
})
</script>

<style scoped>

</style>
