<template>
  <div>
    <div class="d-flex flex-row flex-nowrap">
      <div class="position-relative m-auto">
        <img class="rounded photo-preview pointer" :src="src"
             @click.prevent="preview = {show: true, src: src, title: alt}" :alt="alt">

        <div class="position-absolute" style="top: 7px; right: 5px">
          <p-icon name="close" @click="$emit('edit')" class="text-dark rounded-circle bg-white p-1 ms-2 fs-5"
                  clickable/>
        </div>
      </div>
      <div class="w-auto"></div>
    </div>
    <p-photo-modal :title="preview.title" :src="preview.src" v-if="preview.show"
                   @closed="preview.show = false" @cropped="(data) => {emit('cropped', data); preview.show = false}"/>
  </div>

</template>

<script setup>
import PPhotoModal from '@/components/modals/PhotoModal'
import { defineEmits, defineProps, ref } from 'vue'

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: true,
  },
})
const emit = defineEmits(['edit', 'cropped'])

const preview = ref({})
</script>

<style scoped>
.photo-preview {
  max-height: 175px;
}
</style>
