<template>
  <div v-if="ticket.status === 'lab_review'">
    <button class="btn btn-dark w-100 mb-3"
            v-if="isLab"
            @click.prevent="sendToManager"

            :disabled="!metersFilled">
      Отправить в УК
    </button>
    <div v-if="ticket.managerReviewRejectReason" class="mb-3">
      <div>
        УК отклонила заявку с комментарием: <b>{{ ticket.managerReviewRejectReason }}</b>
      </div>
    </div>
  </div>

  <!-- Lab actions -->
  <div v-if="isLab">
    <hr>
    <div class="d-flex flex-row mb-3">
      <p-tooltip
        text="Редактировать фотографии"
        v-if="ticket.status === 'todo' || ticket.status === 'in_progress' || ticket.status === 'lab_review'"
      >
        <p-icon name="edit" class="me-4" clickable @click.prevent="state.showReportForm = true"/>
      </p-tooltip>

      <div
        v-if="ticket.status !== 'manager_review' && ticket.status !== 'lab_review' && ticket.status !== 'done' && !ticket.deleted">
        <p-tooltip text="Отправить заявку в ожидание" v-if="ticket.status !== 'wait'">
          <p-icon name="schedule" class="me-4" clickable @click.prevent="toggleWait"/>
        </p-tooltip>
        <p-tooltip text="Вернуть из ожидания" v-else>
          <p-icon name="update" class="me-4" clickable @click.prevent="toggleWait"/>
        </p-tooltip>
      </div>

      <div v-if="ticket.status !== 'manager_review' && ticket.status !== 'done'">
        <p-tooltip
          text="Восстановить заявку"
          v-if="ticket.deleted"
        >
          <p-icon name="restore_from_trash" class="me-4" clickable @click.prevent="state.ticketRestoreAlert = true"/>
        </p-tooltip>

        <p-tooltip
          text="Удалить заявку"
          v-else
        >
          <p-icon name="delete" class="me-4" clickable @click.prevent="state.ticketRemoveAlert = true"/>
        </p-tooltip>
      </div>
    </div>
  </div>
  <div v-if="ticket.status === 'manager_review'">
    <button class="btn btn-dark w-100" :disabled="true" v-if="isLab">
      Отправлено в УК
    </button>
    <button class="btn btn-outline-warning w-100 mt-3" v-if="isLab" @click.prevent="state.backToReviewAlert = true">
      Вернуть заявку
    </button>

    <button class="btn btn-dark w-100" v-if="!isLab" @click.prevent="approve">
      Заявка исполнена
    </button>
    <button class="btn btn-outline-warning w-100 mt-3" v-if="!isLab" @click.prevent="state.rejectReviewAlert = true">
      Вернуть в Поверочкую Лабораторию
    </button>
  </div>

  <!-- MODALS -->
  <!-- Mark as deleted -->
  <p-confirmation-modal
    v-if="state.ticketRemoveAlert"
    @accept="deleteTicket"
    @cancel="state.ticketRemoveAlert = false"
  >
    <template v-slot:title>
      Удаление заявки
    </template>
    <template v-slot:confirmation-text>
      Вы уверены, что хотите удалить заявку?
    </template>
  </p-confirmation-modal>
  <!-- Restore from deleted -->
  <p-confirmation-modal
    v-if="state.ticketRestoreAlert"
    @accept="restoreTicket"
    @cancel="state.ticketRestoreAlert = false"
  >
    <template v-slot:title>
      Восстановление заявки
    </template>
    <template v-slot:confirmation-text>
      Вы уверены, что хотите восстановить заявку?
    </template>
  </p-confirmation-modal>
  <!-- Back from manager review by LAB -->
  <p-confirmation-modal
    v-if="state.backToReviewAlert"
    @accept="backToReview"
    @cancel="state.backToReviewAlert = false"
  >
    <template v-slot:title>
      Отозвать заявку с проверки?
    </template>
    <template v-slot:confirmation-text>
      Вы уверены, что хотите отозвать заявку с проверки Управляющей Компанией? После этого можно будет отредактировать заявку и вернуть в УК.
    </template>
  </p-confirmation-modal>
  <!-- Reject on review by MANAGER -->
  <p-reject-review
    v-if="state.rejectReviewAlert"
    @accept="rejectReview"
    @cancel="state.rejectReviewAlert = false"
  />
  <!-- Ticket editor -->
  <p-base-modal v-if="state.showReportForm" @close="state.showReportForm = false">
    <template v-slot:title>
      Отчет по заявке
    </template>
    <template v-slot:content>
      <p-ticker-report-form v-model="ticket" @saved="state.showReportForm = false"/>
    </template>
  </p-base-modal>
</template>

<script setup>
import { defineEmits, defineProps, reactive } from 'vue'
import api from '@/api'
import urls from '@/api/urls'
import { useRouter } from 'vue-router'
import PConfirmationModal from '@/components/modals/ConfirmationModal'
import PTickerReportForm from '@/components/forms/TicketReport'
import PRejectReview from '@/components/modals/RejectReview'
import PBaseModal from '@/components/modals/Base'
import { useModelWrapper } from '@/composables/model'

const router = useRouter()

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  metersFilled: {
    type: Boolean,
    required: true,
  },
  isLab: {
    type: Boolean,
    required: true,
  },
})
const state = reactive({
  ticketRemoveAlert: false,
  ticketRestoreAlert: false,
  showReportForm: false,
  backToReviewAlert: false,
  rejectReviewAlert: false,
})
const emit = defineEmits(['update:modelValue'])
const ticket = useModelWrapper(props, emit)

const sendToManager = async () => {
  const ticketId = router.currentRoute.value.params.id
  await api.get(urls.TICKETS.SEND_TO_MANAGER_REVIEW, { params: { ticket_id: ticketId } })
  ticket.value = await api.get(urls.TICKETS.GET, { params: { ticket_id: ticketId } })
}

const toggleWait = async () => {
  const ticketId = router.currentRoute.value.params.id
  await api.get(urls.TICKETS.TOGGLE_WAIT, { params: { ticket_id: ticketId } })
  ticket.value = await api.get(urls.TICKETS.GET, { params: { ticket_id: ticketId } })
}

const deleteTicket = async () => {
  const ticketId = router.currentRoute.value.params.id
  await api.get(urls.TICKETS.DELETE, { params: { ticket_id: ticketId } })
  await router.push({ name: 'dashboard' })
}

const restoreTicket = async () => {
  const ticketId = router.currentRoute.value.params.id
  await api.get(urls.TICKETS.RESTORE, { params: { ticket_id: ticketId } })
  await router.push({ name: 'dashboard' })
}

const approve = async () => {
  const ticketId = router.currentRoute.value.params.id
  await api.get(urls.TICKETS.CLOSE, { params: { ticket_id: ticketId } })
  ticket.value = await api.get(urls.TICKETS.GET, { params: { ticket_id: ticketId } })
}

const backToReview = async () => {
  const ticketId = router.currentRoute.value.params.id
  ticket.value = await api.get(urls.TICKETS.BACK_TO_LAB_REVIEW, { params: { ticket_id: ticketId } })
  state.backToReviewAlert = false
}

const rejectReview = async (reason) => {
  const ticketId = router.currentRoute.value.params.id
  ticket.value = await api.get(urls.TICKETS.REJECT_REVIEW_MANAGER, { params: { ticket_id: ticketId, reason } })
  state.rejectReviewAlert = false
  await router.push({ name: 'manager-review' })
}
</script>

<style scoped>

</style>
