<template>
  <div>

    <h5>{{ kind }}</h5>
    <p-photo class="w-100 rounded meter-photo border" :src="img" :alt="kind"/>

    <p-input margin="mt-0" class="mt-3 w-100" label="Номер счетчика"
             v-model:value="report.number"
             :error="v$.number.$errors"
             :disabled="disabled"
             required/>

    <p-input margin="mt-0" class="mt-3 w-100" label="Показания счетчика"
             v-model:value="report.score"
             :error="v$.score.$errors"
             type="number"
             :disabled="disabled"
             required/>

    <p-input margin="mt-0" class="mt-3 w-100" label="Модель счетчика"
             v-model:value="report.model"
             :error="v$.model.$errors"
             :disabled="disabled"
             required/>

    <p-datetime-picker
      class="mt-3 w-100"
      label="Дата проведения работ"
      v-model:value="report.workDate"
      :disabled="disabled"
      :error="v$.workDate.$errors"
      required
    />

    <p-datetime-picker
      class="mt-3 w-100"
      label="Дата очередной поверки"
      v-model:value="report.nextWorkDate"
      :disabled="disabled"
      :error="v$.nextWorkDate.$errors"
      required
    />

    <div class="form-check mt-3">
      <input class="form-check-input" type="checkbox" v-model="allowSubmit" id="flexCheckDefault">
      <label class="form-check-label" for="flexCheckDefault">
        Введенные данные соответствуют фотографиям
      </label>
    </div>

    <button class="btn btn-dark px-5 mt-3" @click.prevent="submit" :disabled="!requiredFilled || !allowSubmit">
      Сохранить данные
    </button>
    <p-server-error :error="serverError"/>
  </div>
</template>

<script setup>
import api from '@/api'
import urls from '@/api/urls'
import { requiredRu, serializeDate } from '@/common'
import PServerError from '@/components/ui/ServerError'
import form from '@/composables/form'
import { useModelWrapper } from '@/composables/model'
import { defineEmits, defineProps, ref } from 'vue'

const props = defineProps({
  reportId: {
    type: Number,
    required: true,
  },
  img: {
    type: String,
    required: true,
  },
  ticketId: {
    type: String,
    required: true,
  },
  kind: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  modelValue: {
    type: Object,
    required: true,
  },
})

const allowSubmit = ref(false)
const emit = defineEmits(['next', 'prev', 'saved'])
const report = useModelWrapper(props, emit)

const {
  v$,
  requiredFilled,
  submit,
  serverError,
} = form(report, {
  number: { requiredRu },
  score: { requiredRu },
  model: { requiredRu },
  workDate: { requiredRu },
  nextWorkDate: { requiredRu },
}, async () => {
  if (props.disabled) {
    return
  }

  await api.post(urls.TICKETS.METER_INFO, {
    ticketId: props.ticketId,
    meterKind: props.kind,
    index: props.reportId,

    number: report.value.number,
    score: report.value.score,
    model: report.value.model,
    workDate: serializeDate(report.value.workDate),
    nextWorkDate: serializeDate(report.value.nextWorkDate),
  })
  emit('saved', report)
  emit('next')
})

</script>

<style scoped>
.meter-photo {
  height: 450px !important;
}
</style>
