<template>
  <p-base-modal :show="show" @close="$emit('cancel')">
    <template v-slot:title>
      Возврат заявки в Поверочкую Лабораторию
    </template>
    <template v-slot:content>
      <p>
        Напишите причину отклонения, ее увидит Поверочная Лаборатория.
      </p>
      <p-input label="Комментарий" textarea v-model:value="reason"/>

      <div class="w-100 d-flex justify-content-end">
        <button class="btn btn-dark px-5 me-3" @click.prevent="$emit('accept', reason)" :disabled="!reason">Вернуть</button>
        <button class="btn btn-outline-dark px-5" @click.prevent="$emit('cancel')">Отмена</button>
      </div>
    </template>
  </p-base-modal>
</template>

<script setup>
import PServerError from '@/components/ui/ServerError'
import { computed, defineProps, ref } from 'vue'
import PBaseModal from './Base'

defineProps({
  show: {
    type: Boolean,
    required: false,
    default: true,
  },
  error: null,
})

const reason = ref(null)
</script>

<style scoped>
</style>
